@import url("../../fonts/font.css");

a {
  color: white;
  cursor: pointer;
}

.Page-Not-Found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  color: white;
  background-color: #0091ad;
}

.title-404 {
  font-family: "Karla", sans-serif;
  font-size: 5em;
}

.description-404 {
  font-family: "Inconsolata";
  font-size: 1.5em;
}

.action-404 {
  font-family: "Inconsolata";
  font-size: 1.5em;
}
