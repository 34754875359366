@import url("../../fonts/font.css");

.App {
  text-align: center;
  /* background-color: #0091ad; */
  min-height: 100vh;
}

.App-header {
  font-family: "PlaywriteDELA", sans-serif;
  /* background-color: #0091ad; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(16px + 5vmin);
  color: white;
  padding-top: 33vh;
}

.logo {
  display: flex;
}

.name {
  margin: 0;
}

.icon {
  width: 2em; /* Adjust size as needed */
  height: auto;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2em;
  margin: auto;
  margin-bottom: 2em;
  max-width: min(70vw, 800px);
}
.section-title {
  font-family: "Inconsolata", sans-serif;
  font-style: italic;
  font-size: 2em;
  color: white;
  align-self: flex-start;
}
.section-content {
  font-family: "Karla", sans-serif;
  font-size: 1.25em;
  color: white;
  align-self: flex-start;
  text-align: justify;
}
