@font-face {
  font-family: "PlaywriteDELA";
  src: url("./Playwrite_DE_LA/PlaywriteDELA-VariableFont_wght.ttf")
    format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: "Karla";
  src: url("./Karla/Karla-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: "Karla";
  src: url("./Karla/Karla-Italic-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900;
  font-style: italic;
}

@font-face {
  font-family: "Inconsolata";
  src: url("./Inconsolata/Inconsolata-VariableFont_wdth\,wght.ttf")
    format("truetype");
  font-weight: 100 900;
  font-style: normal;
}
